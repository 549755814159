import React from 'react';
import {IoIosClose} from 'react-icons/io';
import {FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn} from 'react-icons/fa';
import Tippy from '@tippyjs/react';

const AboutOverlay = ({activeStatus, getActiveStatus}) => {
  return (
    <div className={`about-overlay ${activeStatus ? 'active' : ''}`}>
      <div
        className='about-overlay__close'
        onClick={() => {
          getActiveStatus(false);
          document.querySelector('body').classList.remove('overflow-hidden');
        }}
      />
      <div className='about-overlay__content'>
        <button
          className='about-overlay__close-icon'
          onClick={() => {
            getActiveStatus(false);
            document.querySelector('body').classList.remove('overflow-hidden');
          }}>
          <IoIosClose />
        </button>
        <div className='about-overlay__content-container d-flex flex-column justify-content-between h-100'>
          <div className='about-overlay__widget-wrapper'>
            <div className='about-widget'>
              <h2 className='about-widget__title'>About Us</h2>
              <p>
                We make managed laundry service simple, convenient, and reliable for companies with uniform & laundry
                needs.
              </p>
              <p>
                Our mission is to enable any business with laundry service requirements to experience the same online
                ease as when they’re ordering food or scheduling a ride.
              </p>
              <p>
                With just a few clicks our customers can schedule, track & manage their service from anywhere, anytime.
                It’s power at your fingertips.
              </p>
            </div>
          </div>
          <div className='about-overlay__contact-widget'>
            <p className='email'>
              <a href='mailto:hello@valetpress.com'>hello@valetpress.com</a>
            </p>
            <p className='phone'>888-248-3003</p>
            <div className='social-icons'>
              <ul>
                <li>
                  <Tippy content='Twitter' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                    <a href='https://twitter.com/valetpressinc' target='_blank' rel='noreferrer'>
                      <FaTwitter />
                    </a>
                  </Tippy>
                </li>
                <li>
                  <Tippy content='Facebook' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                    <a href='https://www.facebook.com/ValetPressFB/' target='_blank' rel='noreferrer'>
                      <FaFacebookF />
                    </a>
                  </Tippy>
                </li>
                <li>
                  <Tippy content='Instagram' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                    <a href='https://www.instagram.com/valetpressinc/' target='_blank' rel='noreferrer'>
                      <FaInstagram />
                    </a>
                  </Tippy>
                </li>
                <li>
                  <Tippy content='LinkedIn' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                    <a href='https://www.linkedin.com/company/valetpress/' target='_blank' rel='noreferrer'>
                      <FaLinkedinIn />
                    </a>
                  </Tippy>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOverlay;
