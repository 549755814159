import React from 'react';
import {useState, useEffect, Fragment} from 'react';
import {Container} from 'react-bootstrap';
import Link from 'next/link';
import {connect} from 'react-redux';
import {IoIosSearch, IoMdPerson, IoIosMenu, IoIosCart, IoIosHeartEmpty, IoIosShuffle} from 'react-icons/io';
import Navigation from './elements/Navigation';
import AboutOverlay from './elements/AboutOverlay';
import SearchOverlay from './elements/SearchOverlay';
import CartOverlay from './elements/CartOverlay';
import WishlistOverlay from './elements/WishlistOverlay';
import MobileMenu from './elements/MobileMenu';
import {useUser} from '@auth0/nextjs-auth0';
import {fetchError} from '../../redux/actions/commonActions';
import LoadingOverlay from 'react-loading-overlay-ts';
import Image from 'next/image';
import Tippy from '@tippyjs/react';

// const DynamicAboutOverlay = dynamic(() => import('./elements/AboutOverlay'));

const HeaderOne = ({
  aboutOverlay,
  cartItems,
  wishlistItems,
  compareItems,
  fetchError,
  shopButtons,
  spinner,
  activePortal,
}) => {
  const {user, error} = useUser();

  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [offCanvasAboutActive, setOffCanvasAboutActive] = useState(false);
  const [offCanvasSearchActive, setOffCanvasSearchActive] = useState(false);
  const [offCanvasCartActive, setOffCanvasCartActive] = useState(false);
  const [offCanvasWishlistActive, setOffCanvasWishlistActive] = useState(false);
  const [offCanvasMobileMenuActive, setOffCanvasMobileMenuActive] = useState(false);

  useEffect(() => {
    const header = document.querySelector('header');
    if (header) {
      setHeaderTop(header.offsetTop);
      setHeaderHeight(header.offsetHeight);
      window.addEventListener('scroll', handleScroll);
      document.body.style.paddingTop = `${headerHeight}px`;
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scroll, headerHeight]);

  useEffect(() => {
    if (error) {
      fetchError(error.message);
    }
  }, [fetchError, error]);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <Fragment>
      <header className={`topbar-shadow ${scroll > headerTop ? 'is-sticky' : ''}`}>
        <LoadingOverlay
          active={spinner}
          text='Loading...'
          spinner={true}
          styles={{
            spinner: (base) => ({
              ...base,
              width: '100px',
            }),
            content: () => ({
              top: '50%',
              left: '50%',
              position: 'fixed',
            }),
            wrapper: (base) => ({
              ...base,
              // position: 'static',
            }),
            overlay: (base) => ({
              ...base,
              zIndex: '10000000 !important',
            }),
          }}>
          <Container className='wide'>
            <div className='header-content d-flex align-items-center justify-content-between position-relative space-py-mobile-only--15'>
              {/* logo */}
              <div className='header-content__logo d-flex align-items-center ps-md-2'>
                <button
                  onClick={() => {
                    setOffCanvasAboutActive(true);
                    document.querySelector('body').classList.add('overflow-hidden');
                  }}
                  className={`${aboutOverlay === false ? 'd-none' : 'about-overlay-trigger d-none d-xl-block'}`}>
                  <IoIosMenu />
                </button>
                <div className='ps-md-3'>
                  <Link href='/' as={process.env.NEXT_PUBLIC_URL + '/'}>
                    <a>
                      <div className='d-md-none main-logo-mobile position-relative'>
                        <Image
                          src='/layout/logo-vp.png'
                          alt='ValetPress.com'
                          width={56}
                          height={55}
                          layout='responsive'
                        />
                      </div>
                      <div className='d-none d-md-block main-logo position-relative'>
                        <Image
                          src='/layout/logo.png'
                          alt='ValetPress.com'
                          width={162}
                          height={55}
                          layout='responsive'
                        />
                      </div>
                      {/*<img src={'/assets/images/logo.png'} className='img-fluid main-logo' alt='ValetPress.com' />*/}
                    </a>
                  </Link>
                </div>
              </div>

              {/* navigation */}
              <Navigation />

              {/* icons */}
              <div className='header-content__icons space-pl--15'>
                <ul className='d-none d-lg-block'>
                  {user ? (
                    <li className='user-avatar'>
                      <a href={`/${activePortal || 'client'}-portal/`}>
                        <div className='number-circle'>
                          {user.name ? user.name[0].toUpperCase() : user.email ? user.email[0].toUpperCase() : 'U'}
                        </div>
                      </a>
                      <ul className='sub-menu sub-menu--one-column'>
                        {(user.name || user.email) && (
                          <>
                            {user.name && <li className='d-block pb-2'>{user.name}</li>}
                            {user.email !== user.name && <li className='d-block'>{user.email}</li>}
                            <li className='d-block py-2'>
                              <hr />
                            </li>
                          </>
                        )}
                        <li className='d-block'>
                          <Link href='/client-portal/'>
                            <a>Client Portal</a>
                          </Link>
                        </li>
                        <li className='d-block'>
                          <Link href='/agent-portal/'>
                            <a>Agent Portal</a>
                          </Link>
                        </li>
                        <li className='d-block'>
                          <Link href='/cleaner-portal/'>
                            <a>Cleaner Portal</a>
                          </Link>
                        </li>
                        <li className='d-block pb-2'>
                          <hr />
                        </li>
                        <li className='d-block'>
                          <a href={process.env.NEXT_PUBLIC_URL + '/api/auth/logout/'}>Logout</a>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <li>
                      <Tippy content='Login' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                        <a href={`/api/auth/login?returnTo=/${activePortal || 'client'}-portal/`}>
                          <IoMdPerson />
                        </a>
                      </Tippy>
                    </li>
                  )}

                  <li>
                    <Tippy content='Search' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                      <button
                        onClick={() => {
                          setOffCanvasSearchActive(true);
                          document.querySelector('body').classList.add('overflow-hidden');
                        }}>
                        <IoIosSearch />
                      </button>
                    </Tippy>
                  </li>

                  <Tippy content='Compare' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                    <li>
                      <Link href='/shop/compare/' as={process.env.NEXT_PUBLIC_URL + '/shop/compare/'}>
                        <a>
                          <IoIosShuffle />
                          {compareItems.length >= 1 ? (
                            <span className='count'>{compareItems.length ? compareItems.length : ''}</span>
                          ) : (
                            ''
                          )}
                        </a>
                      </Link>
                    </li>
                  </Tippy>
                  <li>
                    <Tippy content='Wishlist' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                      <button
                        onClick={() => {
                          setOffCanvasWishlistActive(true);
                          document.querySelector('body').classList.add('overflow-hidden');
                        }}>
                        <IoIosHeartEmpty />
                        {wishlistItems.length >= 1 ? (
                          <span className='count'>{wishlistItems.length ? wishlistItems.length : ''}</span>
                        ) : (
                          ''
                        )}
                      </button>
                    </Tippy>
                  </li>
                  <li>
                    <Tippy content='Cart' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                      <button
                        onClick={() => {
                          setOffCanvasCartActive(true);
                          document.querySelector('body').classList.add('overflow-hidden');
                        }}>
                        <IoIosCart />
                        {cartItems.length >= 1 ? (
                          <span className='count'>{cartItems.length ? cartItems.length : ''}</span>
                        ) : (
                          ''
                        )}
                      </button>
                    </Tippy>
                  </li>
                </ul>

                <ul className='d-block d-lg-none'>
                  {shopButtons !== false && (
                    <>
                      <li className='ms-2 ms-md-5'>
                        <Tippy content='Compare' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                          <Link href='/shop/compare/' as={process.env.NEXT_PUBLIC_URL + '/shop/compare/'}>
                            <a>
                              <IoIosShuffle />
                              {compareItems.length >= 1 ? (
                                <span className='count'>{compareItems.length ? compareItems.length : ''}</span>
                              ) : (
                                ''
                              )}
                            </a>
                          </Link>
                        </Tippy>
                      </li>
                      <li>
                        <Tippy content='Wishlist' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                          <button
                            onClick={() => {
                              setOffCanvasWishlistActive(true);
                              document.querySelector('body').classList.add('overflow-hidden');
                            }}>
                            <IoIosHeartEmpty />
                            {wishlistItems.length >= 1 ? (
                              <span className='count'>{wishlistItems.length ? wishlistItems.length : ''}</span>
                            ) : (
                              ''
                            )}
                          </button>
                        </Tippy>
                      </li>
                      <li>
                        <Tippy content='Cart' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                          <button
                            onClick={() => {
                              setOffCanvasCartActive(true);
                              document.querySelector('body').classList.add('overflow-hidden');
                            }}>
                            <IoIosCart />
                            {cartItems.length >= 1 ? (
                              <span className='count'>{cartItems.length ? cartItems.length : ''}</span>
                            ) : (
                              ''
                            )}
                          </button>
                        </Tippy>
                      </li>
                    </>
                  )}

                  {user ? (
                    <li className='user-avatar'>
                      <a href={`/${activePortal || 'client'}-portal/`}>
                        <div className='number-circle'>
                          {user.name ? user.name[0].toUpperCase() : user.email ? user.email[0].toUpperCase() : 'U'}
                        </div>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <Tippy content='Login' trigger='mouseenter' animation='shift' arrow={true} duration={0}>
                        <a href={`/api/auth/login?returnTo=/${activePortal || 'client'}-portal/`}>
                          <IoMdPerson />
                        </a>
                      </Tippy>
                    </li>
                  )}

                  <li>
                    <button onClick={() => setOffCanvasMobileMenuActive(true)}>
                      <IoIosMenu />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </LoadingOverlay>
      </header>

      {/* about overlay */}
      {aboutOverlay !== false && (
        <AboutOverlay activeStatus={offCanvasAboutActive} getActiveStatus={setOffCanvasAboutActive} />
      )}
      {/*search overlay*/}
      <SearchOverlay activeStatus={offCanvasSearchActive} getActiveStatus={setOffCanvasSearchActive} />

      {/* cart overlay */}
      <CartOverlay activeStatus={offCanvasCartActive} getActiveStatus={setOffCanvasCartActive} />

      {/* wishlist overlay */}
      <WishlistOverlay activeStatus={offCanvasWishlistActive} getActiveStatus={setOffCanvasWishlistActive} />
      {/* Mobile Menu */}
      <MobileMenu activeStatus={offCanvasMobileMenuActive} getActiveStatus={setOffCanvasMobileMenuActive} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData,
    activePortal: state.profileData.activePortal,
  };
};

const mapDispatchToProps = {
  fetchError,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderOne);
