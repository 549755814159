import React from 'react';
import Link from 'next/link';
import {IoIosClose} from 'react-icons/io';
import CustomScroll from 'react-custom-scroll';
import {connect} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import {deleteFromCart} from '../../../redux/actions/cartActions';
import {unzipUrl} from '../../../services/image';

import 'react-custom-scroll/dist/customScroll.css';

const CartOverlay = ({activeStatus, getActiveStatus, cartItems, deleteFromCart}) => {
  let cartTotalPrice = 0;
  const {addToast} = useToasts();
  return (
    <div className={`cart-overlay ${activeStatus ? 'active' : ''}`}>
      <div
        className='cart-overlay__close'
        onClick={() => {
          getActiveStatus(false);
          document.querySelector('body').classList.remove('overflow-hidden');
        }}
      />
      <div className='cart-overlay__content'>
        {/*=======  close icon  =======*/}
        <button
          className='cart-overlay__close-icon'
          onClick={() => {
            getActiveStatus(false);
            document.querySelector('body').classList.remove('overflow-hidden');
          }}>
          <IoIosClose />
        </button>
        {/*=======  offcanvas cart content container  =======*/}
        <div className='cart-overlay__content-container'>
          <h3 className='cart-title'>Cart</h3>
          {cartItems.length >= 1 ? (
            <div className='cart-product-wrapper'>
              <div className='cart-product-container'>
                <CustomScroll allowOuterScroll={true}>
                  {cartItems.map((product, i) => {
                    const discountedPrice = product.sku.price;
                    cartTotalPrice += discountedPrice * product.quantity;

                    return (
                      <div className='single-cart-product' key={i}>
                        <span className='cart-close-icon'>
                          <button onClick={() => deleteFromCart(product, addToast)}>
                            <IoIosClose />
                          </button>
                        </span>
                        <div className='image'>
                          <Link
                            href={`/shop/p/${product.slug}/`}
                            as={`${process.env.NEXT_PUBLIC_URL}/shop/p/${product.slug}/`}>
                            <a>
                              <img
                                src={
                                  product.sku.colorProductImageThumbnail
                                    ? unzipUrl(product.sku.colorProductImageThumbnail)
                                    : process.env.NEXT_PUBLIC_URL + '/assets/images/placeholder/63x72.png'
                                }
                                alt={product.title}
                              />
                            </a>
                          </Link>
                        </div>
                        <div className='content pe-5'>
                          <h5>
                            <Link
                              href={`/shop/p/${product.slug}/`}
                              as={`${process.env.NEXT_PUBLIC_URL}/shop/p/${product.slug}/`}>
                              <a>{product.title}</a>
                            </Link>
                          </h5>
                          {product.selectedProductColor && product.selectedProductSize ? (
                            <div className='cart-item-variation'>
                              <span>Color: {product.selectedProductColor}</span>
                              <span className='d-inline-block'>Size: {product.selectedProductSize}</span>{' '}
                              {product.selectedProductSize2 && (
                                <span className='d-inline-block'> - {product.selectedProductSize2}</span>
                              )}
                            </div>
                          ) : (
                            ''
                          )}
                          <p>
                            <span className='cart-count'>{product.quantity} x </span>{' '}
                            <span className='discounted-price'>${discountedPrice}</span>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </CustomScroll>
              </div>
              {/*=======  subtotal calculation  =======*/}
              <p className='cart-subtotal'>
                <span className='subtotal-title'>Subtotal:</span>
                <span className='subtotal-amount'>${cartTotalPrice.toFixed(2)}</span>
              </p>
              {/*=======  cart buttons  =======*/}
              <div className='cart-buttons'>
                <Link href='/shop/cart/' as={process.env.NEXT_PUBLIC_URL + '/shop/cart/'}>
                  <a>view cart</a>
                </Link>
                <Link href='/shop/checkout/' as={process.env.NEXT_PUBLIC_URL + '/shop/checkout/'}>
                  <a>checkout</a>
                </Link>
              </div>
              {/*=======  free shipping text  =======*/}
              <p className='free-shipping-text'>Free Shipping on apparel orders over $200.00!</p>
            </div>
          ) : (
            'No items found in cart'
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartOverlay);
