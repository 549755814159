import React from 'react';
import {
  IoIosPhonePortrait,
  IoMdMail,
  IoLogoTwitter,
  IoLogoFacebook,
  IoLogoInstagram,
  IoMdPerson,
  IoLogoLinkedin,
} from 'react-icons/io';
import {useUser} from '@auth0/nextjs-auth0';
import {connect} from 'react-redux';

const MobileMenuWidgets = ({activePortal}) => {
  const {user} = useUser();

  return (
    <div className='offcanvas-mobile-menu__widgets'>
      <div className='contact-widget space-mb--30'>
        <ul>
          {!user && (
            <li>
              <IoMdPerson />
              <a href={`/api/auth/login?returnTo=/${activePortal || 'client'}-portal/`}>Login / Register</a>
            </li>
          )}

          <li>
            <IoIosPhonePortrait />
            <a href='tel://8882483003'>888-248-3003</a>
          </li>
          <li>
            <IoMdMail />
            <a href='mailto:hello@valetpress.com'>hello@valetpress.com</a>
          </li>
        </ul>
      </div>

      <div className='social-widget'>
        <a href='https://twitter.com/valetpressinc' target='_blank' rel='noreferrer'>
          <IoLogoTwitter />
        </a>
        <a href='https://www.instagram.com/valetpressinc/' target='_blank' rel='noreferrer'>
          <IoLogoInstagram />
        </a>
        <a href='https://www.facebook.com/ValetPressFB/' target='_blank' rel='noreferrer'>
          <IoLogoFacebook />
        </a>
        <a href='https://www.linkedin.com/company/valetpress/' target='_blank' rel='noreferrer'>
          <IoLogoLinkedin />
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activePortal: state.profileData.activePortal,
  };
};

export default connect(mapStateToProps, null)(MobileMenuWidgets);
