import React from 'react';
import Link from 'next/link';
import {IoIosClose} from 'react-icons/io';
import CustomScroll from 'react-custom-scroll';
import {connect} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import {deleteFromWishlist} from '../../../redux/actions/wishlistActions';
import {unzipUrl} from '../../../services/image';

import 'react-custom-scroll/dist/customScroll.css';

const WishlistOverlay = ({activeStatus, getActiveStatus, wishlistItems, deleteFromWishlist}) => {
  const {addToast} = useToasts();

  return (
    <div className={`wishlist-overlay ${activeStatus ? 'active' : ''}`}>
      <div
        className='wishlist-overlay__close'
        onClick={() => {
          getActiveStatus(false);
          document.querySelector('body').classList.remove('overflow-hidden');
        }}
      />
      <div className='wishlist-overlay__content'>
        {/*=======  close icon  =======*/}
        <button
          className='wishlist-overlay__close-icon'
          onClick={() => {
            getActiveStatus(false);
            document.querySelector('body').classList.remove('overflow-hidden');
          }}>
          <IoIosClose />
        </button>

        {/*=======  offcanvas wishlist content container  =======*/}
        <div className='wishlist-overlay__content-container'>
          <h3 className='wishlist-title'>Wishlist</h3>
          {wishlistItems.length >= 1 ? (
            <div className='wishlist-product-wrapper'>
              <div className='wishlist-product-container'>
                <CustomScroll allowOuterScroll={true}>
                  {wishlistItems.map((product) => {
                    const discountedPrice = product.sku.price;
                    return (
                      <div className='single-wishlist-product' key={product.id}>
                        <span className='wishlist-close-icon'>
                          <button onClick={() => deleteFromWishlist(product, addToast)}>
                            <IoIosClose />
                          </button>
                        </span>
                        <div className='image'>
                          <Link
                            href={`/shop/p/${product.slug}/`}
                            as={`${process.env.NEXT_PUBLIC_URL}/shop/p/${product.slug}/`}>
                            <a>
                              <img
                                src={
                                  product.sku.colorProductImageThumbnail
                                    ? unzipUrl(product.sku.colorProductImageThumbnail)
                                    : process.env.NEXT_PUBLIC_URL + '/assets/images/placeholder/63x72.png'
                                }
                                alt={product.title}
                              />
                            </a>
                          </Link>
                        </div>
                        <div className='content pe-5'>
                          <h5>
                            <Link
                              href={`/shop/p/${product.slug}/`}
                              as={`${process.env.NEXT_PUBLIC_URL}/shop/p/${product.slug}/`}>
                              <a>{product.title}</a>
                            </Link>
                          </h5>
                          <p>
                            <span className='discounted-price'>${discountedPrice}</span>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </CustomScroll>
              </div>
              {/*=======  wishlist buttons  =======*/}
              <div className='wishlist-buttons'>
                <Link href='/shop/wishlist/' as={process.env.NEXT_PUBLIC_URL + '/shop/wishlist/'}>
                  <a>view wishlist</a>
                </Link>
              </div>
            </div>
          ) : (
            'No items found in wishlist'
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wishlistItems: state.wishlistData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromWishlist: (item, addToast) => {
      dispatch(deleteFromWishlist(item, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WishlistOverlay);
