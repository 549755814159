import React from 'react';
import {useState, useEffect, Fragment} from 'react';
import {Container} from 'react-bootstrap';
import Link from 'next/link';

const HeaderTwo = () => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const header = document.querySelector('header');
    if (header) {
      setHeaderTop(header.offsetTop);
      setHeaderHeight(header.offsetHeight);
      window.addEventListener('scroll', handleScroll);
      document.body.style.paddingTop = `${headerHeight}px`;
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scroll, headerHeight]);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <Fragment>
      <header
        className={`topbar-shadow ${scroll > headerTop ? 'is-sticky' : ''}`}
        style={{
          top: 0,
          left: 0,
          width: '100%',
          paddingTop: '25px',
        }}>
        <Container className='wide'>
          <div className='header-content d-flex align-items-center justify-content-between position-relative space-py-mobile-only--30'>
            {/* logo */}
            <div className='header-content__logo d-flex align-items-center space-pr--15 pb-3'>
              <Link href='/' as={process.env.NEXT_PUBLIC_URL + '/'}>
                <a>
                  <img src={'/assets/images/logo.png'} className='img-fluid main-logo' alt='ValetPress.com' />
                </a>
              </Link>
            </div>
          </div>
        </Container>
      </header>
    </Fragment>
  );
};

export default HeaderTwo;
