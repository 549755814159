import React from 'react';
import {useEffect} from 'react';
import Link from 'next/link';
import {useUser} from '@auth0/nextjs-auth0';

const MobileMenuNav = ({getActiveStatus}) => {
  const {user} = useUser();

  useEffect(() => {
    const offCanvasNav = document.querySelector('#offcanvas-mobile-menu__navigation');
    // Added if statement because from time to time we gt the Sentry error:
    // Cannot read property 'querySelectorAll' of null
    if (offCanvasNav) {
      const offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.mobile-sub-menu');
      const anchorLinks = offCanvasNav.querySelectorAll('a');

      for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
        offCanvasNavSubMenu[i].insertAdjacentHTML('beforebegin', "<span class='menu-expand'><i></i></span>");
      }

      const menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
      const numMenuExpand = menuExpand.length;

      for (let i = 0; i < numMenuExpand; i++) {
        menuExpand[i].addEventListener('click', (e) => {
          sideMenuExpand(e);
        });
      }

      for (let i = 0; i < anchorLinks.length; i++) {
        anchorLinks[i].addEventListener('click', () => {
          getActiveStatus(false);
        });
      }
    }
  });

  const sideMenuExpand = (e) => {
    e.currentTarget.parentElement.classList.toggle('active');
  };
  return (
    <nav className='offcanvas-mobile-menu__navigation' id='offcanvas-mobile-menu__navigation'>
      <ul>
        {user && (
          <>
            {(user.name || user.email) && <li className='pb-3'>{user.name ? user.name : user.email}</li>}
            <li>
              <Link href='/client-portal/'>
                <a>Client Portal</a>
              </Link>
            </li>
            <li>
              <Link href='/agent-portal/'>
                <a>Agent Portal</a>
              </Link>
            </li>
            <li>
              <Link href='/cleaner-portal/'>
                <a>Cleaner Portal</a>
              </Link>
            </li>
            <li>
              <a href={process.env.NEXT_PUBLIC_URL + '/api/auth/logout/'}>Logout</a>
            </li>
            <li className=' py-1'>
              <hr />
            </li>
          </>
        )}
        <li className='menu-item-has-children'>
          <Link href='/about-us/' as={process.env.NEXT_PUBLIC_URL + '/about-us/'}>
            <a>About Us</a>
          </Link>
          <ul className='mobile-sub-menu'>
            <li>
              <Link href='/terms-and-conditions/' as={process.env.NEXT_PUBLIC_URL + '/terms-and-conditions/'}>
                <a>Terms & Conditions</a>
              </Link>
            </li>
            <li>
              <Link href='/privacy-policy/' as={process.env.NEXT_PUBLIC_URL + '/privacy-policy/'}>
                <a>Privacy Policy</a>
              </Link>
            </li>
          </ul>
        </li>

        <li className='menu-item-has-children'>
          <Link href='/partner-with-us/' as={process.env.NEXT_PUBLIC_URL + '/partner-with-us/'}>
            <a>Partner With Us</a>
          </Link>
          <ul className='mobile-sub-menu'>
            <li>
              <Link
                href='/partner-with-us/become-a-cleaner-partner/'
                as={process.env.NEXT_PUBLIC_URL + '/partner-with-us/become-a-cleaner-partner/'}>
                <a>Cleaner Partner</a>
              </Link>
            </li>
            <li>
              <Link
                href='/partner-with-us/become-a-valet-driver/'
                as={process.env.NEXT_PUBLIC_URL + '/partner-with-us/become-a-valet-driver/'}>
                <a>Valet Driver</a>
              </Link>
            </li>
          </ul>
        </li>

        <li className='menu-item-has-children'>
          <Link href='/services/' as={process.env.NEXT_PUBLIC_URL + '/services/'}>
            <a>Our Services</a>
          </Link>
          <ul className='mobile-sub-menu'>
            <li>
              <Link href='/services/business-apparel/' as={process.env.NEXT_PUBLIC_URL + '/services/business-apparel/'}>
                <a>Business Apparel</a>
              </Link>
            </li>
            <li>
              <Link href='/services/laundry-service/' as={process.env.NEXT_PUBLIC_URL + '/services/laundry-service/'}>
                <a>Laundry Service</a>
              </Link>
            </li>
            <li>
              <Link href='/services/dry-cleaning/' as={process.env.NEXT_PUBLIC_URL + '/services/dry-cleaning/'}>
                <a>Dry Cleaning</a>
              </Link>
            </li>
            <li>
              <Link href='/services/pickup-delivery/' as={process.env.NEXT_PUBLIC_URL + '/services/pickup-delivery/'}>
                <a>Pickup & Delivery</a>
              </Link>
            </li>
            <li>
              <Link href='/services/lease/' as={process.env.NEXT_PUBLIC_URL + '/services/lease/'}>
                <a>Garment Lease Program</a>
              </Link>
            </li>
            <li>
              <Link
                href='/services/embroidered-apparel/'
                as={process.env.NEXT_PUBLIC_URL + '/services/embroidered-apparel/'}>
                <a>Embroidered Apparel</a>
              </Link>
            </li>
          </ul>
        </li>

        <li className='menu-item-has-children'>
          <Link href='/markets/' as={process.env.NEXT_PUBLIC_URL + '/markets/'}>
            <a>Markets</a>
          </Link>
          <ul className='mobile-sub-menu'>
            <li>
              <Link href='/markets/hospitality/' as={process.env.NEXT_PUBLIC_URL + '/markets/hospitality/'}>
                <a>Hospitality</a>
              </Link>
            </li>
            <li>
              <Link href='/markets/medical/' as={process.env.NEXT_PUBLIC_URL + '/markets/medical/'}>
                <a>Medical</a>
              </Link>
            </li>
            <li>
              <Link href='/markets/restaurants/' as={process.env.NEXT_PUBLIC_URL + '/markets/restaurants/'}>
                <a>Restaurants</a>
              </Link>
            </li>
            <li>
              <Link href='/markets/industrial/' as={process.env.NEXT_PUBLIC_URL + '/markets/industrial/'}>
                <a>Industrial</a>
              </Link>
            </li>
            <li>
              <Link href='/markets/linens/' as={process.env.NEXT_PUBLIC_URL + '/markets/linens/'}>
                <a>Linens</a>
              </Link>
            </li>
          </ul>
        </li>

        <li className='menu-item-has-children'>
          <Link href='/contact-us/' as={process.env.NEXT_PUBLIC_URL + '/contact-us/'}>
            <a>Contact Us</a>
          </Link>
        </li>

        <li className='menu-item-has-children'>
          <Link href={process.env.NEXT_PUBLIC_SHOP_URL + '/'}>
            <a>Shop Now</a>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileMenuNav;
