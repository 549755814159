export const unzipUrl = (url) => {
  // Unzip url
  if (!url.startsWith('http')) {
    return `https://s3.amazonaws.com/core.valetpress.com/media/products/${url}`;
  }
  return url;
};

export const imageLoader = ({config, src, width, quality}) => {
  // Demo: https://res.cloudinary.com/demo/image/upload/w_300,c_limit,q_auto/turtles.jpg
  const params = ['f_auto', 'c_limit', 'w_' + width, 'q_' + (quality || 'auto')];
  const paramsString = params.join(',') + '/';
  return `https://res.cloudinary.com/valetpress/image/fetch/${paramsString}${src}`;
};
