import React, {useState} from 'react';
import {IoIosSearch} from 'react-icons/io';
import {useRouter} from 'next/router';

const MobileMenuSearch = () => {
  const [keyword, setKeyword] = useState('');
  const router = useRouter();

  const onChange = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <div className='offcanvas-mobile-menu__search'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!keyword) {
            document.querySelector('body').classList.remove('overflow-hidden');
          } else {
            document.querySelector('body').classList.remove('overflow-hidden');
            router.push({
              pathname: '/shop/all/',
              query: {s: keyword},
            });

            setKeyword('');
          }
        }}>
        <input type='search' value={keyword} placeholder='Search here' onChange={onChange} />
        <button type='submit'>
          <IoIosSearch />
        </button>
      </form>
    </div>
  );
};

export default MobileMenuSearch;
