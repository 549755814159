import React from 'react';
import Link from 'next/link';
import {IoIosArrowDown} from 'react-icons/io';
import {Button} from 'react-bootstrap';

const Navigation = () => {
  const CustomButton = ({url, prefill, pageSettings, utm}) => {
    const onClick = async () => {
      const openPopupWidget = (await import('react-calendly')).openPopupWidget;
      openPopupWidget({url, prefill, pageSettings, utm});
    };

    return (
      <>
        <Button variant='link' onClick={onClick}>
          <img
            src={'/assets/images/icon/schedule-call.png'}
            alt='Schedule a Call'
            className='pb-1 me-1'
            width={35}
            height={38}
          />
        </Button>

        <a onClick={onClick} className='highlighted-link d-none d-xxl-inline-block'>
          Schedule a Call
        </a>
      </>
    );
    //
    // return (
    //   <button className='lezada-button lezada-button--outlined-grey lezada-button--small' onClick={onClick}>
    //     Schedule a Call
    //   </button>
    // );
  };

  return (
    <nav className='header-content__navigation space-pr--15 space-pl--15 d-none d-lg-block'>
      <ul>
        {/*className='d-lg-none d-xl-inline-block'*/}
        <li>
          <Link href='/about-us/'>
            <a>About</a>
          </Link>
          <IoIosArrowDown />
          <ul className='sub-menu sub-menu--mega sub-menu--mega--column-2'>
            <li className='sub-menu--mega__title'>
              <Link href='/about-us/'>
                <a>About Us</a>
              </Link>

              <ul className='sub-menu--mega__list'>
                <li>
                  <Link href='/terms-and-conditions/' as={process.env.NEXT_PUBLIC_URL + '/terms-and-conditions/'}>
                    <a>Terms & Conditions</a>
                  </Link>
                </li>
                <li>
                  <Link href='/privacy-policy/' as={process.env.NEXT_PUBLIC_URL + '/privacy-policy/'}>
                    <a>Privacy Policy</a>
                  </Link>
                </li>
              </ul>
            </li>
            <li className='sub-menu--mega__title'>
              <Link href='/partner-with-us/' as={process.env.NEXT_PUBLIC_URL + '/partner-with-us/'}>
                <a>Partner With Us</a>
              </Link>
              <ul className='sub-menu--mega__list'>
                <li>
                  <Link
                    href='/partner-with-us/become-a-cleaner-partner/'
                    as={process.env.NEXT_PUBLIC_URL + '/partner-with-us/become-a-cleaner-partner/'}>
                    <a>Cleaner Partner</a>
                  </Link>
                </li>
                <li>
                  <Link
                    href='/partner-with-us/become-a-valet-driver/'
                    as={process.env.NEXT_PUBLIC_URL + '/partner-with-us/become-a-valet-driver/'}>
                    <a>Valet Driver</a>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <Link href='/services/' as={process.env.NEXT_PUBLIC_URL + '/services/'}>
            <a>Services</a>
          </Link>
          <IoIosArrowDown />
          <ul className='sub-menu sub-menu--one-column'>
            <li>
              <Link href='/services/business-apparel/' as={process.env.NEXT_PUBLIC_URL + '/services/business-apparel/'}>
                <a>Business Apparel</a>
              </Link>
            </li>
            <li>
              <Link href='/services/laundry-service/' as={process.env.NEXT_PUBLIC_URL + '/services/laundry-service/'}>
                <a>Laundry Service</a>
              </Link>
            </li>
            <li>
              <Link href='/services/dry-cleaning/' as={process.env.NEXT_PUBLIC_URL + '/services/dry-cleaning/'}>
                <a>Dry Cleaning</a>
              </Link>
            </li>
            <li>
              <Link href='/services/pickup-delivery/' as={process.env.NEXT_PUBLIC_URL + '/services/pickup-delivery/'}>
                <a>Pickup & Delivery</a>
              </Link>
            </li>
            <li>
              <Link href='/services/lease/' as={process.env.NEXT_PUBLIC_URL + '/services/lease/'}>
                <a>Garment Lease Program</a>
              </Link>
            </li>
            <li>
              <Link
                href='/services/embroidered-apparel/'
                as={process.env.NEXT_PUBLIC_URL + '/services/embroidered-apparel/'}>
                <a>Embroidered Apparel</a>
              </Link>
            </li>
          </ul>
        </li>

        <li className='position-relative'>
          <Link href='/markets/' as={process.env.NEXT_PUBLIC_URL + '/markets/'}>
            <a>Markets</a>
          </Link>

          <IoIosArrowDown />
          <ul className='sub-menu sub-menu--one-column'>
            <li>
              <Link href='/markets/hospitality/' as={process.env.NEXT_PUBLIC_URL + '/markets/hospitality/'}>
                <a>Hospitality</a>
              </Link>
            </li>
            <li>
              <Link href='/markets/medical/' as={process.env.NEXT_PUBLIC_URL + '/markets/medical/'}>
                <a>Medical</a>
              </Link>
            </li>
            <li>
              <Link href='/markets/restaurants/' as={process.env.NEXT_PUBLIC_URL + '/markets/restaurants/'}>
                <a>Restaurants</a>
              </Link>
            </li>
            <li>
              <Link href='/markets/industrial/' as={process.env.NEXT_PUBLIC_URL + '/markets/industrial/'}>
                <a>Industrial</a>
              </Link>
            </li>
            <li>
              <Link href='/markets/linens/' as={process.env.NEXT_PUBLIC_URL + '/markets/linens/'}>
                <a>Linens</a>
              </Link>
            </li>
          </ul>
        </li>
        {/* d-lg-none d-xl-inline-block*/}
        <li className='position-relative'>
          <Link href='/contact-us/' as={process.env.NEXT_PUBLIC_URL + '/contact-us/'}>
            <a>Contact</a>
          </Link>
        </li>
        <li className='position-relative'>|</li>

        <li className='position-relative highlighted-link d-xl-none'>
          <Link href={process.env.NEXT_PUBLIC_SHOP_URL + '/'}>
            <a className='highlighted-link'>Shop</a>
          </Link>
        </li>

        <li className='position-relative highlighted-link d-none d-xl-inline-block'>
          <Link href={process.env.NEXT_PUBLIC_SHOP_URL + '/'}>
            <a className='highlighted-link'>Shop Now</a>
          </Link>
        </li>

        <li className='position-relative highlighted-link'>
          <CustomButton
            url={'https://calendly.com/d/cgf-8gz-8b7/valetpress-managed-uniform-laundry-service-review-call'}
          />
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
