import React, {useEffect, useRef, useState} from 'react';
import {MdClose} from 'react-icons/md';
import {useRouter} from 'next/router';

const SearchOverlay = ({activeStatus, getActiveStatus}) => {
  const [keyword, setKeyword] = useState('');
  const router = useRouter();

  const inputRef = useRef(null);

  useEffect(() => {
    if (activeStatus && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, activeStatus]);

  const onChange = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <div className={`search-overlay ${activeStatus ? 'active' : ''}`}>
      {/*=======  close icon  =======*/}
      <button
        className='search-overlay__close-icon'
        onClick={() => {
          getActiveStatus(false);
          document.querySelector('body').classList.remove('overflow-hidden');
        }}>
        <MdClose />
      </button>
      {/*=======  End of close icon  =======*/}
      {/*=======  search overlay content  =======*/}
      <div className='search-overlay__content'>
        <form
          className='space-mb--20'
          onSubmit={(e) => {
            e.preventDefault();
            if (!keyword) {
              getActiveStatus(false);
              document.querySelector('body').classList.remove('overflow-hidden');
            } else {
              getActiveStatus(false);
              document.querySelector('body').classList.remove('overflow-hidden');

              router.push({
                pathname: '/shop/all/',
                query: {s: keyword},
              });

              setKeyword('');
            }
          }}>
          <input ref={inputRef} value={keyword} type='search' placeholder='Search Products...' onChange={onChange} />
        </form>
        <div className='search-overlay__hint'># Hit enter to search</div>
      </div>
      {/*=======  End of search overlay content  =======*/}
    </div>
  );
};

export default SearchOverlay;
